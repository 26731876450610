import api from './api';

const checkIsUserActive = async (userId) => {
  console.log('user id in check user status', userId);
  if (!userId) {
    console.error('Invalid userId:', userId);
    return false;
  }
  try {
    const res = await api.get(`api/cehckuserstatus/${userId}`);

    console.log('res in check user', res);
    return res.data;
  } catch (error) {
    console.error('Error in checkIsUserActive:', error); // Log the error
    return false;
  }
};

export default checkIsUserActive;
