import React, { useState } from 'react';
import axios from 'axios';
import NutritionCard from '../../component/Calorie/NutritionCard';

const NutritionFactsComponent = () => {
  const [foodName, setFoodName] = useState('');
  const [amount, setAmount] = useState('');
  const [unit, setUnit] = useState('g');
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('idle');
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');
    if (!foodName || !amount) {
      alert('لطفاً تمام فیلدها را پر کنید.');
      return;
    }

    // Construct userInput and serving_size as strings
    const userInput = `${foodName} ${amount}${unit}`;
    const servingSize = `${amount}${unit}`; // Send as a string

    const payload = {
      userInput: `${foodName} ${amount}${unit}`, // Constructed as a string
      foodItems: {
        name: foodName,
        serving_size: `${amount}${unit}`, // Ensure this is a string
      },
      userId: 'guest',
      selectedMeal: 'default',
    };

    try {
      const response = await axios.post(
        'http://localhost:8090/nutritionextractor',
        payload
      ); // Replace URL with the correct endpoint
      setResponseData(response.data);
      setError(null);
      setStatus('success');
    } catch (err) {
      setError(err.response?.data?.error || 'خطا در ارسال درخواست');
      setResponseData(null);
      setStatus('error');
    }
  };

  return (
    <div style={{ textAlign: 'center', direction: 'rtl', padding: '20px' }}>
      <h2>اطلاعات تغذیه‌ای غذا</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '10px' }}>
          <label>نام غذا:</label>
          <input
            className="input input-bordered w-full max-w-xs"
            type="text"
            value={foodName}
            onChange={(e) => setFoodName(e.target.value)}
            placeholder="نام غذا را وارد کنید"
            required
            style={{ marginRight: '10px' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>مقدار:</label>
          <select
            className="select select-bordered w-full max-w-xs"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
            style={{ marginRight: '10px' }}>
            <option value="" disabled>
              انتخاب مقدار
            </option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>واحد:</label>
          <select
            value={unit}
            className="select select-bordered w-full max-w-xs"
            onChange={(e) => setUnit(e.target.value)}
            required
            style={{ marginRight: '10px' }}>
            <option value="g">گرم (g)</option>
            <option value="kg">کیلوگرم (kg)</option>
            <option value="ml">میلی‌لیتر (ml)</option>
            <option value="l">لیتر (l)</option>
          </select>
        </div>
        <button
          disabled={status === 'loading'}
          className="btn btn-success"
          type="submit"
          style={{ marginTop: '10px' }}>
          ارسال
        </button>
      </form>
      {status === 'loading' && (
        <span className="loading loading-ring loading-lg"></span>
      )}
      {responseData && (
        <div style={{ marginTop: '20px' }}>
          <h3>نتیجه:</h3>
          <NutritionCard responseData={responseData} />
          {/* <pre style={{ background: '#f4f4f4', padding: '10px' }}>
            {JSON.stringify(responseData, null, 2)}
          </pre> */}
        </div>
      )}

      {error && (
        <div style={{ marginTop: '20px', color: 'red' }}>
          <h3>خطا:</h3>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default NutritionFactsComponent;
