import React from 'react'; //import { Utensils, Clock, Leaf, Scale, Heart } from 'lucide-react';

const NutritionCard = ({ responseData }) => {
  console.log('responseData in Car', responseData);
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const NutrientRow = ({ label, amount, unit }) => (
    <div className="flex justify-between py-2 border-b border-gray-100">
      <span className="text-gray-600">{label}</span>
      <span className="font-medium">
        {amount} {unit}
      </span>
    </div>
  );

  return (
    <div className="max-w-2xl mx-auto p-6">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        {/* Header */}
        <div className="bg-gradient-to-r from-blue-500 to-blue-600 p-6 text-white">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              {/* <Utensils className="h-6 w-6" /> */}
              <h2 className="text-2xl font-bold">
                {responseData.currentFood.foodItems[0].food_item}
              </h2>
            </div>
            <div className="flex items-center space-x-2">
              {/* <Clock className="h-5 w-5" /> */}
              <span className="text-sm">
                {formatDate(responseData.currentFood.date)}
              </span>
            </div>
          </div>
          <div className="mt-4 flex items-center space-x-2">
            {/* <Scale className="h-5 w-5" /> */}
            <span>
              سروینگ سایز:{' '}
              {responseData.currentFood.foodItems[0].serving_size.amount}
              {responseData.currentFood.foodItems[0].serving_size.unit}
            </span>
          </div>
        </div>

        {/* Main Content */}
        <div className="p-6">
          {/* Calories Section */}
          <div className="mb-6 text-center">
            <div className="inline-flex items-center justify-center p-3 bg-blue-50 rounded-full mb-2">
              {/* <Leaf className="h-6 w-6 text-blue-500" />
            </div> */}
              <div className="text-3xl font-bold text-gray-800">
                {responseData.data.calories}
              </div>
              <div className="text-sm text-gray-500">کالری</div>
            </div>

            {/* Macronutrients Grid */}
            <div className="grid grid-cols-2 gap-4 mb-6">
              <div className="bg-gray-50 p-4 rounded-lg text-center">
                <div className="text-lg font-semibold text-gray-800">
                  {responseData.data.protein}g
                </div>
                <div className="text-sm text-gray-500">پروتیین</div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg text-center">
                <div className="text-lg font-semibold text-gray-800">
                  {responseData.data.total_fat}g
                </div>
                <div className="text-sm text-gray-500">چربی ها</div>
              </div>
            </div>

            {/* Detailed Nutrients */}
            <div className="space-y-1">
              <NutrientRow
                label="چربی اشباع شده"
                amount={responseData.data.saturated_fat}
                unit="g"
              />
              <NutrientRow
                label="کلسترول"
                amount={responseData.data.cholesterol}
                unit="mg"
              />
              <NutrientRow
                label="سدیم"
                amount={responseData.data.sodium}
                unit="mg"
              />
              <NutrientRow
                label="جمع کربوهیدرات"
                amount={responseData.data.total_carbohydrates}
                unit="g"
              />
              <NutrientRow
                label="فیبر"
                amount={responseData.data.dietary_fiber}
                unit="g"
              />
              <NutrientRow
                label="قند"
                amount={responseData.data.sugars}
                unit="g"
              />
            </div>

            {/* Vitamins and Minerals */}
            <div className="mt-6">
              <div className="flex items-center space-x-2 mb-4">
                {/* <Heart className="h-5 w-5 text-blue-500" /> */}
                <h3 className="text-lg font-semibold text-gray-800">
                  ویتامین ها و مینرال ها
                </h3>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <NutrientRow
                  label="ویتامین A"
                  amount={
                    responseData.data.vitamins_and_minerals.vitamin_a.amount
                  }
                  unit={responseData.data.vitamins_and_minerals.vitamin_a.unit}
                />
                <NutrientRow
                  label="ویتامین C"
                  amount={
                    responseData.data.vitamins_and_minerals.vitamin_c.amount
                  }
                  unit={responseData.data.vitamins_and_minerals.vitamin_c.unit}
                />
                <NutrientRow
                  label="کلسیم"
                  amount={
                    responseData.data.vitamins_and_minerals.calcium.amount
                  }
                  unit={responseData.data.vitamins_and_minerals.calcium.unit}
                />
                <NutrientRow
                  label="آهن"
                  amount={responseData.data.vitamins_and_minerals.iron.amount}
                  unit={responseData.data.vitamins_and_minerals.iron.unit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NutritionCard;
