import api from './api';

const getProductData = async () => {
  try {
    const response = await api.get('/productlist');
    const { data } = response;
    // console.log(response.data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

const handleGetCoachreports = async (userId) => {
  try {
    const response = await api.get(`/coachresponse/${userId}`);
    const { data } = response;
    // console.log('coach report in getData', data);
    return data.length > 0 ? data : null;
  } catch (error) {
    return null;
  }
};

const calculateMacros = (calories, protein, carbs, fat) => {
  const proteinPercentage = 0.3 || protein;
  const carbsPercentage = 0.4 || carbs;
  const fatPercentage = 0.3 || fat;

  const proteinCalories = calories * proteinPercentage;
  const carbsCalories = calories * carbsPercentage;
  const fatCalories = calories * fatPercentage;

  const proteinGrams = proteinCalories / 4;
  const carbsGrams = carbsCalories / 4;
  const fatGrams = fatCalories / 9;

  return { protein: proteinGrams, carbs: carbsGrams, fat: fatGrams };
};

export { getProductData, handleGetCoachreports, calculateMacros };
