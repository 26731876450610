import api from './api';

const getUserData = async (userId) => {
  if (!userId) {
    //  console.error('Invalid userId provided:', userId);
    return null; // Avoid making a call with invalid ID
  }

  try {
    const response = await api.get(`/api/getuser/${userId}`);
    // console.log('Fetched user data:', response.data);
    return response.data;
  } catch (err) {
    // console.error('Error fetching user data:', err);
    return null;
  }
};

export default getUserData;
