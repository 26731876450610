import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import ImageUpload from '../../../api/uploadImages';
const Report = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState();
  const [userProducts, setUserProducts] = useState();
  const [userPermission, setUserPermission] = useState(false);

  const todayName = new Date().toLocaleDateString('en-US', {
    weekday: 'long',
  });
  const yesterday = new Date().toLocaleDateString('fa-IR', {
    weekday: 'long',
  });
  const tomorrow = new Date().toLocaleDateString('fa-IR', {
    weekday: 'long',
  });

  //console.log(todayName);

  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      try {
        const parsedCredentials = JSON.parse(credentials);
        setIsLoggedIn(true);
        setUserId(parsedCredentials.id);
        setUserProducts(JSON.parse(localStorage.getItem('userProduct')));

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoggedIn(false);
        setIsLoading(false);
      }
    } else {
      setIsLoggedIn(false);
      setIsLoading(false);
    }
  }, []);

  const reportType = [
    {
      name: 'ارسال گزارش هفتگی',
      link: '/dashboard/report/weeklyreport',
      limited: true,
      reportLink: '/dashboard/weekly-history',
      ActiveReport: true,
      // historytitle: 'ارسال گزارش هفتگی فقط از طریق ربات تلگرام امکان پذیر است',
    },
    {
      name: ' ارسال تصاویر قبل/بعد',
      link: '/dashboard/report/beforeafter',
      limited: false,
      reportLink: '/dashboard/beforeafterhistory',
      ActiveReport: true,
    },
    {
      name: ' ارسال گزارش سایز ',
      link: '/dashboard/report/size',
      limited: false,
      reportLink: '/dashboard/size-history',
      ActiveReport: true,
    },
    {
      name: 'آزمایش خون، تست بادی انالیز',
      link: '/dashboard/report/otherfiles',
      limited: false,
      reportLink: '/dashboard/beforeafterhistory',
      ActiveReport: true,
    },
  ];

  const checkValidDay = (productName) => {
    const todayName = new Date().toLocaleDateString('en-US', {
      weekday: 'long',
    });

    const validDays = [
      {
        name: 'Shape up 7',
        day: 'Tuesday',
      },
      {
        name: 'Shape up pro 6',
        day: 'Sunday',
      },
    ];

    const product = validDays.find((p) => p.name === productName);
    console.log('productName', product ? product.day === todayName : false);

    return product ? product.day === todayName : false;
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100 font-custom1">
        Loading...
      </div>
    );
  }

  return (
    <div className="w-full bg-gray-100 font-custom1">
      <div className="flex flex-col gap-3 items-center p-6">
        {reportType.map((item) => (
          <div
            key={item.name}
            className="md:w-1/3 w-full bg-base-100 mx-3 shadow-xl p-3 rounded-lg">
            <div className="">
              <h2 className="card-title"></h2>
              <p className="text-center text-red-500">
                {!item.limited ||
                checkValidDay(userProducts?.[userProducts.length - 1]?.name) ? (
                  <Link
                    to={userPermission ? '#' : item.link} // Disable link if no permission
                    state={{ userId: userId }}
                    className={`bg-blue-700 text-white px-4 py-2 rounded-lg mt-4 block text-center ${
                      userPermission ? 'bg-red-500 cursor-not-allowed' : ''
                    }`}
                    onClick={(e) => userPermission && e.preventDefault()} // Prevent navigation if no permission
                  >
                    {userPermission
                      ? 'شما اجازه دسترسی به این صفحه را ندارید'
                      : item.name}
                  </Link>
                ) : (
                  'مهلت ارسال گزارش به پایان رسیده است'
                )}
              </p>

              <div className="card-actions justify-end text-xs">
                <div className="">
                  <Link
                    to={
                      item.ActiveReport && !userPermission
                        ? item.reportLink
                        : '#'
                    }
                    state={{ userId: userId }}
                    className={`text-blue-700 underline mt-4 block ${
                      userPermission ? 'cursor-not-allowed' : ''
                    }`}
                    onClick={(e) => userPermission && e.preventDefault()} // Prevent navigation if no permission
                  >
                    {!userPermission
                      ? item.historytitle
                        ? item.historytitle
                        : ''
                      : 'شما اجازه دسترسی به این صفحه را ندارید'}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Report;
